import styled from 'styled-components'
import { defaultBorderWithRadius } from '../../../../../lib/styles/Container'

export const StyledShortfactsCard = styled.div`
  ${defaultBorderWithRadius};
  padding: 2.5rem;
  display: flex;
  align-items: center;
  gap: 2rem;

  @media screen and (max-width: 480px) {
    width: 100%;
    flex-direction: column;
    padding: 2rem !important;
    gap: 2rem;
  }
`
export const StyledCompanyImageWrapper = styled.div`
  width: 6rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-content: center;
  & img {
    object-fit: contain;
  }
`

export const StyledCompanyInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
`

export const StyledCompanyInformationRow = styled.div`
  display: flex;
  min-height: 1rem;
  gap: 2rem;
`

export const StyledSperator = styled.span`
  margin-inline: 0.4rem;
  color: ${({ theme }) => theme.colors.black[4]};
`
