import styled from 'styled-components'
import { Card } from '../../../components/UI'
import { getBreakPoint } from '../../../lib/styles/breakpoints'
import { Swiper } from 'swiper/react'

export const StyledCard = styled(Card)`
  overflow: hidden;
  padding-inline: 2.5rem;

  @media screen and (${getBreakPoint('sm', 'max')}) {
    padding-inline: 1.5rem;
  }
`

export const StyledSwiper = styled(Swiper)`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;

  & .swiper-pagination {
    display: flex;
    height: 1rem;
    gap: 0.5rem;
  }

  & .swiper-pagination-bullet {
    display: block;
    height: 0.5rem;
    width: 0.5rem;

    background-color: ${({ theme }) => theme.colors.black[6]};
    z-index: 100;
    margin: unset;
    cursor: pointer;
    border-radius: 50%;

    &.swiper-pagination-bullet-active {
      background-color: ${({ theme }) => theme.colors.black[1]};
    }
  }

  @media screen and (max-width: 820px) {
    width: 100%;
    max-width: 24rem;
  }
`
