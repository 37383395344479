import { BlogHeaderBase } from '../../../components/Blog/BlogHeader/Base'
import { BlogContent } from '../../../components/Blog/BlogContent'
import * as React from 'react'
import { BlogHeaderDefault } from '../../../components/Blog/BlogHeader/Default'
import { BlogPostDefaultLayoutProps } from './types'
import { BlogFooterDefault } from '../../../components/Blog/BlogFooter/BlogFooterDefault'
import { BlogComponentType } from '../../../types'

export const BlogPostDefaultLayout = ({ content, components, pageContext }: BlogPostDefaultLayoutProps) => (
  <>
    <BlogHeaderBase
      title={content.title}
      categoryTitle={content.postCategory}
      publishDate={pageContext.first_published_at}
      updatedDate={pageContext.published_at}
      component={pageContext.field_component as BlogComponentType}
    >
      <BlogHeaderDefault
        title={content.title}
        mediaSrc={content.media.filename}
        mediaCaption={content.mediaCaption}
        isVideo={!!content.media.is_external_url}
        introText={content.introText}
        alt={content.media.alt || content.media.name}
      />
    </BlogHeaderBase>
    <BlogContent>{components}</BlogContent>
    <BlogFooterDefault content={content} />
  </>
)
