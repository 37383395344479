import * as React from 'react'
import { Seo } from '../../components/Seo'
import { DynamicComponent } from '../../components/DynamicComponent'
import { DefaultLayout } from '../../layouts/DefaultLayout'
import { useStoryblok } from '../../lib/storyblok'
import { SbBlogContent } from './types'
import { BlogComponentType, SbPageContext } from '../../types'
import { BlogPostCustomerStoriesLayout } from './BlogPostCustomerStoriesLayout'
import { BlogPostUseCasesLayout } from './BlogPostUseCasesLayout'
import { BlogPostDefaultLayout } from './BlogPostDefaultLayout'
import { useContactFormData } from '../../lib/hooks/useContactFormData'
import { ContactPanel } from '../../modules/ContactPanel'
import styled from 'styled-components'
import { componentCategoryMap } from '../../lib/constants'

export const StyledContactPanel = styled(ContactPanel)`
  margin-top: 8rem;
`

const BlogPostTemplate = ({ pageContext }: { pageContext: SbPageContext }) => {
  let { content } =
    typeof window !== 'undefined'
      ? useStoryblok<SbBlogContent>(pageContext, window.location)
      : useStoryblok<SbBlogContent>(pageContext)

  const { content: blogContent } = content || {}

  const components = Array.isArray(blogContent)
    ? blogContent?.map(blok => {
        return <DynamicComponent blok={blok} key={blok._uid} />
      })
    : undefined

  const categoryTitle = componentCategoryMap[content.component]

  let usedTemplate: React.ReactNode

  switch (content.component) {
    case BlogComponentType.CUSTOMER_STORIES: {
      usedTemplate = (
        <BlogPostCustomerStoriesLayout
          content={content}
          pageContext={pageContext}
          components={components}
          categoryTitle={categoryTitle}
        />
      )
      break
    }
    case BlogComponentType.USE_CASE:
      usedTemplate = (
        <BlogPostUseCasesLayout
          content={content}
          pageContext={pageContext}
          components={components}
          categoryTitle={categoryTitle}
        />
      )
      break
    case BlogComponentType.PDF_KNOWLEDGE:
    case BlogComponentType.PRODUCT_NEWS:
    case BlogComponentType.COMPANY_INSIGHTS: {
      usedTemplate = <BlogPostDefaultLayout content={content} pageContext={pageContext} components={components} />
      break
    }
  }

  const formData = useContactFormData(content.callToAction)

  return (
    <DefaultLayout>
      {/*
        @TODO
          title = headline
          description – intro text
          (concerns use cases, customer stories, company insights, product news, pdf knowledge)
      */}
      <Seo
        title={pageContext?.field_pageTitle_string}
        description={pageContext?.field_pageDescription_string}
        lang={pageContext?.lang}
        url={pageContext?.full_slug}
      />
      {usedTemplate}
      <StyledContactPanel blok={{ ...formData }} />
    </DefaultLayout>
  )
}

export default BlogPostTemplate
