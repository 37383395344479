import { BlogHeaderShortFactsCard } from '../Shared/ShortfactsCard'
import React from 'react'
import { useTranslation } from '../../../../lib/context/i18n'
import { TranslationKey } from '../../../../lib/context/i18n/interfaces'
import { IntroText, IntroTextWrapper } from '../styled'
import { BlogHeaderUseCasesProps } from './types'

export const BlogHeaderUseCases = ({ introText, image, usedProducts }: BlogHeaderUseCasesProps) => {
  const t = useTranslation()

  return (
    <>
      <BlogHeaderShortFactsCard
        image={image}
        usedProducts={usedProducts.map(product => product.content?.productName)}
        labels={{
          usedProducts: t(TranslationKey.general_ProductsForThisCase),
        }}
      />
      {introText && (
        <IntroTextWrapper>
          <IntroText>{introText}</IntroText>
        </IntroTextWrapper>
      )}
    </>
  )
}
