import { SbBlogCustomerStoriesContent } from '../../../../templates/BlogPostTemplate/types'
import { useTranslation } from '../../../../lib/context/i18n'
import { ProductTeaser } from '../../../../modules/ProductTeaser'
import { Hint } from '../../../../modules/Hint'
import { TranslationKey } from '../../../../lib/context/i18n/interfaces'
import { ShareBar } from '../ShareBar'
import * as React from 'react'
import { Author } from '../../Author'
import { StyledBlogFooter } from '../styled'

export const BlogFooterCustomerStories = ({ content }: { content: SbBlogCustomerStoriesContent }) => {
  const t = useTranslation()

  return (
    <StyledBlogFooter>
      {content.usedProducts && content.usedProducts.length > 0 && <ProductTeaser usedProducts={content.usedProducts} />}
      {content.briefPortrait && (
        <Hint
          blok={{
            headline: `${t(TranslationKey.customerStory_briefPortrait)} ${content.companyName}`,
            content: content.briefPortrait,
          }}
        />
      )}
      <ShareBar />

      {content.author && <Author author={content.author} />}
    </StyledBlogFooter>
  )
}
