import { StyledBlogFooter } from '../styled'
import { ProductTeaser } from '../../../../modules/ProductTeaser'
import { ShareBar } from '../ShareBar'
import * as React from 'react'
import { SbBlogUseCaseContent } from '../../../../templates/BlogPostTemplate/types'

export const BlogFooterUseCases = ({ content }: { content: SbBlogUseCaseContent }) => (
  <StyledBlogFooter>
    {content.usedProducts && content.usedProducts.length > 0 && <ProductTeaser usedProducts={content.usedProducts} />}
    <ShareBar />
  </StyledBlogFooter>
)
