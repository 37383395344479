import styled from 'styled-components'
import { typoHeading6, typoParagraphS } from '../../../lib/styles/Typography'

export const StyledAuthor = styled.div`
  background-color: ${({ theme }) => theme.colors.black[7]};
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  gap: 1rem;
  margin-top: 2.225rem;

  @media screen and (max-width: 576px) {
    flex-direction: column;
  }
`

export const StyledAuthorAside = styled.div`
  flex: 1 0 auto;
`

export const StyledAuthorImage = styled.div`
  img {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    object-fit: cover;
  }
`

export const StyledAuthorMain = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`

export const StyledAuthorName = styled.h3`
  ${typoHeading6}
`

export const StyledAuthorDescription = styled.p`
  ${typoParagraphS}
`
