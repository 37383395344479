import { BlogHeaderBase } from '../../../components/Blog/BlogHeader/Base'
import { BlogContent } from '../../../components/Blog/BlogContent'
import * as React from 'react'
import { BlogHeaderUseCases } from '../../../components/Blog/BlogHeader/UseCases'
import { BlogPostUseCasesLayoutLayoutProps } from './types'
import { BlogFooterUseCases } from '../../../components/Blog/BlogFooter/BlogFooterUseCases'
import { BlogComponentType } from '../../../types'

export const BlogPostUseCasesLayout = ({
  categoryTitle,
  content,
  pageContext,
  components,
}: BlogPostUseCasesLayoutLayoutProps) => (
  <>
    <BlogHeaderBase
      title={content.title}
      categoryTitle={categoryTitle}
      publishDate={pageContext.first_published_at}
      updatedDate={pageContext.published_at}
      component={BlogComponentType.USE_CASE}
    >
      <BlogHeaderUseCases
        introText={content.introText}
        image={content.useCaseIcon}
        usedProducts={content.usedProducts}
      />
    </BlogHeaderBase>
    <BlogContent>{components}</BlogContent>
    <BlogFooterUseCases content={content} />
  </>
)
