import * as React from 'react'
import { useTranslation } from '../../../lib/context/i18n'
import {
  StyledAuthor,
  StyledAuthorAside,
  StyledAuthorDescription,
  StyledAuthorImage,
  StyledAuthorMain,
  StyledAuthorName,
} from './styled'

export const Author = ({ author }) => {
  const t = useTranslation()
  const { fullName, shortDescription, authorImage } = author.content || {}
  const name = fullName ? fullName : author.name

  return (
    <StyledAuthor>
      <StyledAuthorAside>
        {authorImage && authorImage.filename && (
          <StyledAuthorImage>
            <img
              src={authorImage.filename}
              alt={authorImage.alt || authorImage.title || name}
              title={authorImage.alt || authorImage.title || name}
            />
          </StyledAuthorImage>
        )}
      </StyledAuthorAside>
      <StyledAuthorMain>
        <StyledAuthorName>
          {t('blog_author_preheadline')} {name}
        </StyledAuthorName>
        <StyledAuthorDescription>{shortDescription}</StyledAuthorDescription>
      </StyledAuthorMain>
    </StyledAuthor>
  )
}
