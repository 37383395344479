import styled from 'styled-components'
import { typoHeading5 } from '../../../../lib/styles/Typography'

export const StyledShareBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.5rem 0.9rem;
  margin-top: 6.225rem;
`

export const StyledShareBarTitle = styled.h3`
  ${typoHeading5}
`

export const StyledShareBarButtons = styled.div`
  display: flex;
  gap: 0.2rem;
`
