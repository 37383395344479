import * as React from 'react'
import CustomLink from '../../../CustomLink'
import { Button } from '../../../UI'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useTranslation } from '../../../../lib/context/i18n'
import { StyledShareBar, StyledShareBarButtons, StyledShareBarTitle } from './styled'

export const ShareBar = () => {
  const t = useTranslation()

  const [currentUrl, setCurrentUrl] = React.useState('')

  React.useEffect(() => {
    setCurrentUrl(window.location.href)
  }, [])

  const linkedInUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${currentUrl}`
  const twitterUrl = `https://twitter.com/share?url=${currentUrl}`
  const facebookUrl = `https://www.facebook.com/sharer.php?u=${currentUrl}`

  return (
    <StyledShareBar>
      <StyledShareBarTitle>{t('blog_share_bar_title')}</StyledShareBarTitle>
      <StyledShareBarButtons>
        <Button
          as={CustomLink}
          to={linkedInUrl}
          $size="small"
          $variant="secondary"
          $ignoreTheme
          iconLeft={`logo-linkedin`}
          target="_blank"
          title={`${t('blog_share_bar_button_social_title')} LinkedIn`}
        />
        <Button
          as={CustomLink}
          to={twitterUrl}
          $size="small"
          $variant="secondary"
          $ignoreTheme
          iconLeft={`logo-twitter`}
          target="_blank"
          title={`${t('blog_share_bar_button_social_title')} Twitter`}
        />
        <Button
          as={CustomLink}
          to={facebookUrl}
          $size="small"
          $variant="secondary"
          $ignoreTheme
          iconLeft={`logo-facebook`}
          target="_blank"
          title={`${t('blog_share_bar_button_social_title')} Facebook`}
        />
        <CopyToClipboard text={currentUrl}>
          <Button
            $ignoreTheme
            $size="small"
            $variant="secondary"
            iconLeft={`copy`}
            title={t('blog_share_bar_button_copy_title')}
          />
        </CopyToClipboard>
      </StyledShareBarButtons>
    </StyledShareBar>
  )
}
