import { TestimonialSliderItem } from '../Item'
import * as React from 'react'
import { Autoplay, Pagination, SwiperOptions } from 'swiper'
import { SwiperSlide } from 'swiper/react'
import { BlogTestimonialSliderProps } from './types'
import { StyledCard, StyledSwiper } from './styled'
import 'swiper/css'

const swiperOptions: SwiperOptions = {
  modules: [Pagination, Autoplay],
  spaceBetween: 60,
  speed: 200,
  slidesPerView: 1,
  effect: 'fade',
  fadeEffect: {
    // Add this
    crossFade: true,
  },
  pagination: {
    clickable: true,
  },
  loopedSlides: 3,
  loopedSlidesLimit: false,
  autoplay: {
    delay: 4000,
    pauseOnMouseEnter: true,
  },
}

export const BlogTestimonialSlider = ({ testimonials }: BlogTestimonialSliderProps) => {
  if (testimonials.length <= 1) {
    swiperOptions.pagination = false
  }

  return (
    <StyledCard cardIcon="quotes">
      <StyledSwiper {...swiperOptions} id="swiper">
        {testimonials.map(testimonial => (
          <SwiperSlide key={testimonial._uid}>
            <TestimonialSliderItem
              image={testimonial.portraitImage}
              name={testimonial.name}
              position={testimonial.position}
              quote={testimonial.quote}
              enforceRoundImage
            />
          </SwiperSlide>
        ))}
      </StyledSwiper>
    </StyledCard>
  )
}
