import { MediaElement } from '../../../../UI/MediaElement'
import { BlogHeroWrapper } from '../../styled'
import * as React from 'react'
import {
  StyledCompanyImageWrapper,
  StyledCompanyInformationRow,
  StyledCompanyInformationWrapper,
  StyledShortfactsCard,
  StyledSperator,
} from './styled'
import { BlogHeaderShortfactsCardItem } from '../ShortfactsCardItem'
import { useTranslation } from '../../../../../lib/context/i18n'
import { BlogHeaderInfoBoxProps } from './types'
import { TranslationKey } from '../../../../../lib/context/i18n/interfaces'

export const BlogHeaderShortFactsCard = ({
  image,
  companySize,
  country,
  industry,
  usedProducts,
  labels,
}: BlogHeaderInfoBoxProps) => {
  const t = useTranslation()

  const UsedProducts = usedProducts.map((product, index) => {
    if (index < usedProducts.length - 1) {
      return (
        <span key={index}>
          {product}
          <StyledSperator>/</StyledSperator>
        </span>
      )
    }
    return <span key={index}>{product}</span>
  })

  return (
    <BlogHeroWrapper>
      <StyledShortfactsCard>
        {image && image.filename && (
          <StyledCompanyImageWrapper>
            <MediaElement mediaSrc={image?.filename} title={image.title} />
          </StyledCompanyImageWrapper>
        )}
        <StyledCompanyInformationWrapper>
          {(industry || country || companySize) && (
            <StyledCompanyInformationRow>
              {industry && (
                <BlogHeaderShortfactsCardItem
                  value={industry}
                  label={labels?.industry || t(TranslationKey.general_industry)}
                />
              )}
              {country && (
                <BlogHeaderShortfactsCardItem
                  value={country}
                  label={labels?.country || t(TranslationKey.general_country)}
                />
              )}
              {companySize && (
                <BlogHeaderShortfactsCardItem
                  value={companySize}
                  label={labels?.companySize || t(TranslationKey.general_companySize)}
                />
              )}
            </StyledCompanyInformationRow>
          )}
          <StyledCompanyInformationRow>
            <BlogHeaderShortfactsCardItem label={labels?.usedProducts || t(TranslationKey.general_usedProducts)}>
              {UsedProducts}
            </BlogHeaderShortfactsCardItem>
          </StyledCompanyInformationRow>
        </StyledCompanyInformationWrapper>
      </StyledShortfactsCard>
    </BlogHeroWrapper>
  )
}
