import { IntroText, IntroTextWrapper } from '../styled'
import * as React from 'react'
import { BlogHeaderShortFactsCard } from '../Shared/ShortfactsCard'
import { CustomerStoriesBlogHeaderProps } from './types'
import styled from 'styled-components'
import { containerContent } from '../../../../lib/styles/Container'
import { BlogTestimonialSlider } from '../../../../modules/TestimonialSlider/BlogTestimonialSlider'

const StyledTestimonialsWrapper = styled.div`
  ${containerContent}
  display: flex;
  overflow: hidden;
  margin-block: 4rem;
`

export const BlogHeaderCustomerStories = ({
  country,
  companySize,
  industry,
  usedProducts,
  companyLogo,
  introText,
  testimonials,
}: CustomerStoriesBlogHeaderProps) => {
  return (
    <>
      <BlogHeaderShortFactsCard
        image={companyLogo}
        companySize={companySize}
        country={country}
        industry={industry}
        usedProducts={usedProducts}
      />
      {introText && (
        <IntroTextWrapper>
          <IntroText>{introText}</IntroText>
        </IntroTextWrapper>
      )}
      {testimonials.length > 0 && (
        <StyledTestimonialsWrapper>
          <BlogTestimonialSlider testimonials={testimonials} />
        </StyledTestimonialsWrapper>
      )}
    </>
  )
}
