import styled from 'styled-components'
import { typoLabel } from '../../../../../lib/styles/Typography'

export const StyledInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1.5;
`

export const StyledInfoItemLabel = styled.span`
  ${typoLabel}
  color: ${({ theme }) => theme.colors.black[3]};
  line-height: 1.5;
`
export const StyledInfoItemText = styled.span`
  ${typoLabel};
  color: ${({ theme }) => theme.colors.black[1]};
  line-height: 1.5;
`
