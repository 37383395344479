import { SbBlogContent } from '../../../../templates/BlogPostTemplate/types'
import { StyledBlogFooter } from '../styled'
import { ShareBar } from '../ShareBar'
import { Author } from '../../Author'
import * as React from 'react'
import { useReccuringModule } from '../../../../lib/hooks/useReccuringModule'

export const BlogFooterDefault = ({ content }: { content: SbBlogContent }) => (
  <StyledBlogFooter>
    <ShareBar />
    {content.author && <Author author={useReccuringModule(content.author)} />}
  </StyledBlogFooter>
)
