import { BlogHeaderMediaCaption, BlogMediaHeroWrapper, IntroText, IntroTextWrapper } from '../styled'
import { MediaElement } from '../../../UI/MediaElement'
import * as React from 'react'
import { DefaultBlogHeaderProps } from './types'

export const BlogHeaderDefault = ({
  title,
  mediaSrc,
  isVideo,
  alt,
  mediaCaption,
  introText,
}: DefaultBlogHeaderProps) => {
  return (
    <>
      {!!mediaSrc && (
        <BlogMediaHeroWrapper>
          <figure>
            <MediaElement mediaSrc={mediaSrc} isVideo={isVideo} alt={alt} title={title} />
            {!!mediaCaption && <BlogHeaderMediaCaption>{mediaCaption}</BlogHeaderMediaCaption>}
          </figure>
        </BlogMediaHeroWrapper>
      )}
      {introText && (
        <IntroTextWrapper>
          <IntroText>{introText}</IntroText>
        </IntroTextWrapper>
      )}
    </>
  )
}
