import { BlogHeaderBase } from '../../../components/Blog/BlogHeader/Base'
import { BlogContent } from '../../../components/Blog/BlogContent'
import * as React from 'react'
import { BlogHeaderCustomerStories } from '../../../components/Blog/BlogHeader/CustomerStories'
import { BlogPostCustomerStoriesLayoutProps } from './types'
import { BlogFooterCustomerStories } from '../../../components/Blog/BlogFooter/BlogFooterCustomerStories'
import { BlogComponentType } from '../../../types'

export const BlogPostCustomerStoriesLayout = ({
  content,
  components,
  categoryTitle,
  pageContext,
}: BlogPostCustomerStoriesLayoutProps) => (
  <>
    <BlogHeaderBase
      title={content.title}
      categoryTitle={categoryTitle}
      publishDate={pageContext.first_published_at}
      updatedDate={pageContext.published_at}
      component={BlogComponentType.CUSTOMER_STORIES}
    >
      <BlogHeaderCustomerStories
        country={content.country}
        companySize={content.companySize}
        industry={content.industry}
        usedProducts={content.usedProducts.map(product => product.content.productName)}
        introText={content.introText}
        companyLogo={content.companyLogo}
        testimonials={content.testimonials}
      />
    </BlogHeaderBase>
    <BlogContent>{components}</BlogContent>
    <BlogFooterCustomerStories content={content} />
  </>
)
