import { StyledInfoItem, StyledInfoItemLabel, StyledInfoItemText } from './styled'
import * as React from 'react'

export const BlogHeaderShortfactsCardItem = ({
  label,
  value,
  children,
}: {
  label: string
  value?: string
  children?: React.ReactNode
}) => (
  <StyledInfoItem>
    <StyledInfoItemLabel>{label}</StyledInfoItemLabel>
    <StyledInfoItemText>{children ? children : value}</StyledInfoItemText>
  </StyledInfoItem>
)
