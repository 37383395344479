import styled from 'styled-components'
import { containerHeadline } from '../../../lib/styles/Container'

export const IntroTextWrapper = styled.div`
  margin: auto;
  margin-top: 3rem;
  ${containerHeadline};
`

export const BlogHeaderMediaCaption = styled.figcaption`
  color: ${({ theme }) => theme.colors.black[3]};
  font-size: 0.9rem;
  line-height: 1.5;
  margin-top: 0.5rem;
  text-align: center;
`

export const BlogHeroWrapper = styled.div`
  margin: 0 auto;
  max-width: 44rem;
  width: 85vw;

  @media screen and (max-width: 768px) {
    width: 95vw;
  }
  overflow: hidden;
`

export const BlogMediaHeroWrapper = styled.div`
  margin: 0 auto;
  max-width: 44rem;
  width: 85vw;

  @media screen and (max-width: 768px) {
    width: 95vw;
  }

  border-radius: 1rem;
  overflow: hidden;

  img,
  video,
  iframe {
    aspect-ratio: 16/9;
    border: 0;
    display: block;
    object-fit: cover;
    width: 100%;
  }
`

export const IntroText = styled.p`
  font-size: 1.2rem;
  line-height: 1.5;
  margin-top: 3rem;
`
