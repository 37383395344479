import * as React from 'react'
import styled from 'styled-components'
import { containerContent } from '../../../lib/styles/Container'

const StyledBlogContent = styled.div`
  ${containerContent};
`

export const BlogContent = ({ children }) => {
  return <StyledBlogContent className="prose">{children}</StyledBlogContent>
}
