import styled from 'styled-components'
import { containerHeadline } from '../../../../lib/styles/Container'
import { typoHeading1 } from '../../../../lib/styles/Typography'
import { BlogComponentType } from '../../../../types'
import { blogComponentTypeColorMap } from '../../../../lib/theme'

export const TitleWrapper = styled.div`
  margin: 0 auto 3rem;
  ${containerHeadline}
`
export const BlogHeaderWrapper = styled.div`
  margin: 4rem 0 5rem 0;
`

export const PostCategory = styled.div<{ $component: BlogComponentType }>`
  color: ${({ $component, theme }) => blogComponentTypeColorMap({ component: $component, theme })};
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  text-align: center;
`

export const PostTitle = styled.h1`
  ${typoHeading1};
  text-align: center;
`

export const PostMeta = styled.div`
  color: ${({ theme }) => theme.colors.black[3]};
  font-size: 0.8rem;
  font-weight: 600;
  margin-top: 1.5rem;
  text-align: center;
`
