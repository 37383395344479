import { formatDate } from '../../../../lib/utils/formatDate'
import * as React from 'react'
import { BlogHeaderWrapper, PostCategory, PostMeta, PostTitle, TitleWrapper } from './styled'
import { BlogHeaderBaseProps } from './types'

export const BlogHeaderBase = ({
  categoryTitle,
  title,
  publishDate,
  updatedDate,
  children,
  component,
}: BlogHeaderBaseProps) => {
  return (
    <BlogHeaderWrapper>
      <TitleWrapper>
        <PostCategory $component={component}>{categoryTitle}</PostCategory>
        <PostTitle>{title}</PostTitle>
        <PostMeta>
          {formatDate(publishDate)}
          {formatDate(publishDate) !== formatDate(updatedDate) && `, Updated ${formatDate(updatedDate)}`}
        </PostMeta>
      </TitleWrapper>
      {children}
    </BlogHeaderWrapper>
  )
}
